<template>
	<div>
		<van-field
		  v-model="phone"
		  clearable
		  name="手机号"
		  label="手机号"
		  placeholder="手机号"
		/>
		<div style="display: flex;">
			<van-field
			  v-model="sms"
			  clearable
			  name="验证码"
			  label="验证码"
			  placeholder="验证码"
			  style="width: 70%;"
			/>
			<div style="width: 30%;">
				<van-button slot="button" type="primary" @click="getCode">
					{{time}}
				</van-button>
			</div>
		</div>
		<div style="margin: 16px;">
			<van-button round block type="primary" @click="onSubmit">
				绑定用户
			</van-button>
		</div>
	</div>
</template>

<script>
	import request from "@/api/index.js";
	import { title } from "@/settings.js";
	export default {
		name: '',
		data() {
			return {
				title: title,
				isClick: true,
				time: '获取验证码',
				phone: '',
				sms: '',
				openId: ''
			}
		},
		created() {
			this.openId = this.$route.query.openId
		},
		methods: {
			// 获取验证码
			async getCode() {
			  if (this.isClick) {
				if (/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(this.phone)) {
					if(this.title == '赣州水务股份有限公司'){
						request.get(process.env.VUE_APP_GZ_PHOENIX_URL + '/wechat/publicAccount/sendMessageCode',{ params: { phone: this.phone }}).then(res=>{
							if(res.code == 200){
								this.$toast.success('发送成功')
							}else{
								this.$toast.fail('发送失败')
							}
						})
					}else{
						request.get('/wechat/publicAccount/sendMessageCode',{ params: { phone: this.phone }}).then(res=>{
							if(res.code == 200){
								this.$toast.success('发送成功')
							}else{
								this.$toast.fail('发送失败')
							}
						})
					}
					this.isClick = false
					let s = 60
					this.time = s + 's'
					let interval = setInterval(() => {
						s--
						this.time = s + 's'
						if (s < 0) {
						  this.time = '重新获取'
						  this.isClick = true
						  clearInterval(interval)
						}
					}, 1000)
				} else {
				  this.$toast('请输入正确的手机号码')
				}
			  }
			},
			onSubmit() {
				if(this.phone == ''){
					this.$toast('请输入手机号码')
					return;
				}
				if(!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(this.phone)) {
					this.$toast('请输入正确的手机号码')
					return;
				}
				if(this.sms == ''){
					this.$toast('请输入验证码')
					return;
				}
				if(this.openId == ''){
					this.$toast('用户openId为空')
					return;
				}
				let data={
				  phone: this.phone,
				  sms: this.sms,
				  openId: this.openId,
				}
				if(this.title == '赣州水务股份有限公司'){
					request.post(process.env.VUE_APP_GZ_PHOENIX_URL + '/wechat/publicAccount/bindOpenIdWithPhone', data).then(res=>{
						if(res.code == 200){
							this.$toast.success('绑定成功')
							this.$router.push({path: '/gzBindPhoneSuccess', query: {openId: this.openId, phone: this.phone}})
						}else{
							this.$toast.fail(res.msg)
						}
					})
				}else{
					request.post('/wechat/publicAccount/bindOpenIdWithPhone', data).then(res=>{
						if(res.code == 200){
							this.$toast.success('绑定成功')
							this.$router.push({path: '/waterFeeHistory'})
						}else{
							this.$toast.fail(res.msg)
						}
					})
				}
			}
		}
	}
</script>

<style>
</style>